.contractList {
    font-size: 12px;

    td {
        padding: 3px !important;
    }

    th {
        background-color: #fff !important;
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
}