body {
	min-height: 100vh;
}

.logo {
	display: block;
	max-width: 250px;
	margin: 0 auto;
	height: auto;
}

.site_title img {
	max-width: 100%;
	height: auto;
	padding-right: 15px;
}

.nav_title {
	padding-top: 15px;
	height: 70px;
}

.panel_toolbox > li {
	float: right;
}

.btn-xs {
	margin-bottom: 0;
}

.login-submit {
	float: none !important;
	margin-left: 0 !important;
}

.x_title h2 {
	font-size: 18px;
}

//.required label { color: maroon }

.rate-input {
	max-width: 120px;
}

.btn-xs {
	margin-bottom: 5px;
}

.bg-danger {
	background-color: #f2dede !important;
}

.comment-danger {
	width: 100%;
	background-color: #d9534f;
	font-size: 14px;
	margin-bottom: 10px;
	display: block;
	text-align: left;
	font-weight: normal;
	padding: 10px 17px;
}

.table-striped-activity > tbody > tr:nth-of-type(4n-1), .table-striped-activity > tbody > tr:nth-of-type(4n) {
	background-color: #f9f9f9;
}

@media screen and (max-width: 1300px) {
	.x_content-activity {
		overflow: scroll;
	}
}

.main_menu .fas {
	width: 26px;
	opacity: .99;
	display: inline-block;
	font-size: 15px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.active a span.fas {
	text-align: right!important;
	margin-right: 4px;
}

.main_menu span.fas {
	float: right;
	text-align: center;
	margin-top: 5px;
	font-size: 10px;
	min-width: inherit;
	color: #C4CFDA;
}

// Datatable styles
#dataTableBuilder_length {
	width: 40%;
	float: left;
	label {
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		select {
			width: 75px;
			display: inline-block;
			height: 30px;
			line-height: 30px;
			border-radius: 0;
			padding: 5px 10px;
			font-size: 12px;
			color: #555555;
			background-color: #fff;
			background-image: none;
			border: 1px solid #ccc;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          	//transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
		}
	}
}

#dataTableBuilder_paginate {
	a {
		padding: 6px 9px !important;
		background: #ddd !important;
		border-color: #ddd !important
	}
	ul.pagination {
		margin-top: 0;
	}
}
#dataTableBuilder_wrapper {
	position: relative;
	clear: both;
	zoom: 1
}
#dataTableBuilder_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 30px;
	margin-left: -125px;
	margin-top: -15px;
	padding: 14px 0 2px 0;
	border: 1px solid #ddd;
	text-align: center;
	color: #999;
	font-size: 14px;
	background-color: white
}
#dataTableBuilder_length {
	width: 40%;
	float: left
}
#dataTableBuilder_filter {
	width: 50%;
	float: right;
	text-align: right
}
#dataTableBuilder_info {
	width: 60%;
	float: left
}
#dataTableBuilder_paginate {
	float: right;
	text-align: right
}

#dataTableBuilder_scroll {
	clear: both
}
#dataTableBuilder_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch
}
.top #dataTableBuilder_info {
	float: none
}
.clear {
	clear: both
}
#dataTableBuilder_empty {
	text-align: center
}

.customTableFilterButtonsRow {
	//margin-bottom: -40px;
	//z-index: 2;
}

.page-title {
	.title_full {
		width: 100%;
		float: left;
		display: block;

		h3 {
			margin: 9px 0
		}
	}
}

// Work Record styles
.work-record {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    display: table;

    .input-group {
        margin-bottom: 0;
    }

    &__row {
        display: table-row;

        &--head {

        }

        &:nth-of-type(4n-1) {
            background-color: #f9f9f9;
        }

        &--hidden {
            display: none;
        }
    }

    &__item {
        display: table-cell;
        padding: 8px;
        line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid #ddd;
        text-align: left;

        .work-record__row--head & {
            font-weight: bold;
            vertical-align: bottom;
            border-bottom: 2px solid #ddd;
            border-top: 0;
        }
    }
}

.list-add-new {
    cursor: pointer;
}

.v--modal-overlay .v--modal-box {
    overflow: visible !important;
}

.left_col {
	background: transparent;
}

.nav_title {
	background: transparent;
}

.main_container {
	background: transparent url(../images/sidebar-bg.png) no-repeat left bottom;
	background-attachment: fixed;
	position: relative;
}

body {
	color: #6d6d6d;
	background: #2b2b2b;
	font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.471;
}

.nav.side-menu > li.active > a {
	background: linear-gradient(#3e3d3d, #353535), #7b7c7d;
}

.nav-md ul.nav.child_menu li:before {
	background: #676767;
}

.nav-md ul.nav.child_menu li:after {
	border-left: 1px solid #676767;
}

.nav.side-menu > li.current-page, .nav.side-menu > li.active {
	border-right: 5px solid #e06317;
}

.nav.side-menu li.active-sm {
	border-right: 5px solid #e06317;
}

.nav.child_menu > li > a {
	color: rgba(255, 255, 255, 0.75);
	font-size: 12px;
	padding: 9px;
}

.nav.side-menu > li > a, .nav.child_menu > li > a {
	color: #E7E7E7;
	font-weight: 500;
}

.form-control:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(233, 162, 102, 0.6) !important;
	border-color: #e69962 !important;
}

body, body.container, .main_container {
	min-height: 100vh;
}

.nav-md .container.body .right_col {
	min-height: calc(100vh - 49px);
}

a {
	color: #696969;
}

.dropdown-menu > li > a {
	color: #6b6b6b;
}

.nav-sm ul.nav.child_menu {
	background: #2b2b2b;
}
.nav.side-menu > li {
	background: #2b2b2b;
}
.laborer-avatar {
	max-width: 100%;
	padding-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.item-image-placeholder {
	height: 32px;
	width: 32px;
	float: left;
	display: inline-block;
	border-radius: 2px;
	padding: 2px;
	background: #ededed;
	border: 1px solid #e6e6e6;
}